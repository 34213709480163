.forgotpassword-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotpassword-screen__form {
  width: 380px;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
}

.forgotpassword-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.forgotpassword-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}
